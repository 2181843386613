@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_ROW4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  --text: #fa58b6;
  --highlighted-text: #f800b6;
  --main-background: #1a1a40;
  --background2: #270082;
  --foreground1: #700b97;
  --foreground2: #b900ff;
  --main-font: "Roboto Mono", sans-serif;
  --large-font-size: 45px;
  --medium-font-size: 25px;
  --page-size: 100vh;
  background-color: #1a1a40;
}

.dummy-container {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1299;
  width: 150vw;
  height: calc(71px + 1rem + 56px + 1rem + 0px);
  background: linear-gradient(var(--main-background) 0%, rgba(0, 0, 0, 0) 100%);
}
#root {
  background-color: #1a1a40;
  color: var(--text);
  font-family: var(--main-font);
  font-size: var(--medium-font-size);
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 0 !important;
}
.nav-container {
  position: fixed;

  width: 100%;

  z-index: 10000;
  padding: 1rem;
}

.nav-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  color: var(--text);
}

/* a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
} */
.nav-bar a {
  opacity: 40%;
  text-decoration: none;
  margin: 0 1rem;
}
.nav-bar a:hover {
  transform: scale(1.1);
}
.nav-bar .active {
  opacity: 100%;
  border-bottom: 2px solid #fa58b6;
}

.home {
  height: var(--page-size);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem;
}

.intro-left-container {
  display: flex;
  flex-flow: column nowrap;
  margin-right: 2rem;
}
.intro-links {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.links {
  width: 70px;
}
.links:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.intro-text-container {
  /* width: 80%; */
  font-size: var(--large-font-size);
  overflow-wrap: break-word;
  display: flex;
  justify-content: center;
}
.intro-text {
  text-align: left;
  padding: 1rem;
}
.intro-right-container {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.child {
  position: absolute;
}

.child-1 {
  width: clamp(345px, 120%, 600px);
  position: relative;
  top: 70%;
  /* z-index: -10000; */
}

.child-2 {
  width: clamp(453px, 30vw, 700px);
}

.project {
  height: var(--page-size);
  display: flex;
  flex-flow: row nowrap;
}
.project .image-container {
  position: relative;
  top: 10vh;
  /* flex-basis: 30%; */
  /* width: 100%; */
}
.project .inner1 {
  position: absolute;
  width: clamp(398px, 25vw, 700px);
  /* z-index: -90; */
}
.project .inner2 {
  position: absolute;
  width: clamp(481px, 25vw, 700px);
  /* z-index: -100; */
}
.project .content-container {
  padding: 2rem;
  width: 100%;
  /* flex-basis: %; */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  padding-left: 30vw;
}

.content {
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
}
.task-card.flipped > .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}
.front,
.back {
  position: absolute;
  background-position: center;
  background-size: cover;
  width: 250px;
  height: 320px;
  background: var(--foreground1);
  color: var(--highlighted-text);
  vertical-align: middle;
  text-align: left;
  padding: 1rem;
  border-radius: 5px;
  backface-visibility: hidden;
  display: flex;
  flex-flow: column nowrap;
}
.button-container {
  display: flex;
  gap: 1vw;
}
/* a {
  color: none;
  text-decoration: none;
}

a:hover {
  color: none;
  text-decoration: none;
  cursor: pointer;
} */
#taskcard-button {
  /* padding: 0.5rem; */
  /* width: 0%; */
  width: 50%;
  cursor: pointer;
  font-size: var(--medium-font-size)-1vw;
  color: var(--highlighted-text);
  background: var(--foreground1);
  border-radius: 16px;
  border: none;
  text-decoration: none;
  font-family: var(--main-font);
}
#taskcard-button:hover,
#taskcard-button:focus {
  background: var(--foreground2);
  outline: 1px solid var(--highlighted-text);
}
.back {
  position: relative;
  background: var(--background2);
  color: var(--text);
  transform: rotateY(180deg);

  justify-content: space-between;
}
.front {
  justify-content: center;
  align-items: center;
}
.front img {
  margin: 1.5rem;
  width: 50%;
}
.front:hover {
  transform: scale(1.05);
}
.contact {
  height: var(--page-size);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.contact .text-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 2rem;
  max-width: 60vw;
}
.contact .email {
  font-weight: bold;
  color: var(--highlighted-text);
  overflow-wrap: break-word;
}
.contact .image {
  width: 250px;
}
@media only screen and (max-width: 1000px) {
  .project .inner1 {
    display: none;
  }
  .project .inner2 {
    width: 350px;
  }

  .project .content-container {
    padding-left: 0;
  }
}
@media only screen and (max-width: 770px) {
  .home .intro-right-container {
    display: none;
  }
  .contact .text-container {
    max-width: 90vw;
  }
}

/* @media only screen and (max-width: 576px) {
  :root {
    --large-font-size: 45px;
    --medium-font-size: 25px;
  }
  .links {
    width: 66px;
  }
} */
@supports (gap: 1vw) {
  .intro-left-container {
    gap: 3vw;
  }
  .intro-links {
    gap: 6vw;
  }
  .project .content-container {
    gap: 10vw;
  }
}
@supports not (gap: 2px) {
  .links {
    box-sizing: content-box;
    padding-right: 48px;
    padding-left: 48px;
  }
  .task-card {
    padding-right: 48px;
    padding-left: 48px;
  }
}

@supports (width: clamp(1px, 2vw, 3px)) {
  html {
    --large-font-size: clamp(45px, 4.18vw, 5rem);
    --medium-font-size: clamp(25px, 2.5vw, 3rem);
  }
  .links {
    width: clamp(75px, 8%, 150px);
  }
  .front,
  .back {
    width: clamp(250px, 20.85vw, 400px);
    height: clamp(300px, 27.8vw, 600px);
  }
  .contact .image {
    width: clamp(250px, 25vw, 700px);
  }
}
